<template>
<v-currency-field solo :label="label" :error-messages="errorMessages" filled v-model.number="number" :value-as-integer="true" :min="min" :max="max"
:decimal-length="0" :allow-negative="allow_negative" @input="updateValue()" v-on="$listeners" />
</template>

<script>
export default {
    props: {
        min: Number,
        max: Number,
        allow_negative: Boolean,
        label: String,
        value: Number,
        errorMessages: Array 
    },
    data: () => ({
        errors: {},
        number: 0,
    }),
    created () {
        this.number = this.value;
    },
    watch: {
        value: function(newVal, _odlValue){
            this.number = newVal;
        }
    },
    methods: {
        updateValue(){
           this.$emit('input', this.number)
        },
    }
}
</script>
